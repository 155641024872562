import React from "react";
import { Link } from "react-router-dom";

import Avatar from "../../shared/components/UIElements/Avatar/Avatar";
import Card from "../../shared/components/UIElements/Card/Card";
import "./UserItem.css";

function UserItem(props) {
  return (
    <li className="user-item">
      <Card className="user-item__content">
        {/**This link works like anchor tag */}
        <Link to={`/${props.id}/places`}>
          <div className="user-item__image">
            {/** this image is replaced by Avatar component which is reaponsible for image styling<img src={props.image} alt={props.name} />*/}
            <Avatar image={props.image} alt={props.name} />
          </div>
          <div className="user-item__info">
            <h2>{props.name}</h2>
            <h3>
              {props.placeCount} {props.placeCount === 1 ? "Place" : "Places"}
            </h3>
          </div>
        </Link>
      </Card>
    </li>
  );
}
export default UserItem;
