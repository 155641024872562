import React from "react";

import UsersList from "../components/UsersList";

function Users() {
  const USERS = [
    {
      id: "u1",
      name: "Meshwa Mehta",
      image:
        "https://images.pexels.com/photos/1478685/pexels-photo-1478685.jpeg",
      places: 3,
    },
  ];
  return <UsersList items={USERS} />;
}
export default Users;
