import React, { useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Users from "./user/pages/Users";
import NewPlace from "./places/pages/NewPlace";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import UserPlaces from "./places/pages/UserPlaces";
import UpdatePlace from "./places/pages/UpdatePlace";
import Authenticate from "./user/pages/Authenticate";
import { AuthContext } from "./shared/context/auth-context";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const login = useCallback(() => {
    setIsLoggedIn(true);
  }, []);
  const logout = useCallback(() => {
    setIsLoggedIn(false);
  }, []);
  let routes;
  if (isLoggedIn) {
    routes = (
      <Switch>
        <Route path="/" exact={true}>
          <Users />
        </Route>
        <Route path="/:userId/places" exact={true}>
          <UserPlaces />
        </Route>
        <Route path="/places/new" exact={true}>
          <NewPlace />
        </Route>
        <Route path="/places/:placeId" exact={true}>
          <UpdatePlace />
        </Route>

        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact={true}>
          <Users />
        </Route>
        <Route path="/:userId/places" exact={true}>
          <UserPlaces />
        </Route>
        <Route path="/auth" exact={true}>
          <Authenticate />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }
  return (
    <AuthContext.Provider
      value={{ isLoggedIn: isLoggedIn, login: login, logout: logout }}
    >
      <Router>
        <MainNavigation />
        {/**main tag is for styling purpose */}
        <main>
          {/*Switch make sure that at time only one route is executed instead of executing every routes one by one */}

          {/* *exact prop is make sure that router only execute Users component when there is only /
            <Route path="/" exact={true}>
              <Users />
            </Route>
            here userId will get info from userItem where in link user id is mentioned 
            <Route path="/:userId/places" exact={true}>
              <UserPlaces />
            </Route>
            <Route path="/places/new" exact={true}>
              <NewPlace />
            </Route>
            <Route path="/places/:placeId" exact={true}>
              <UpdatePlace />
            </Route>
            <Route path="/auth" exact={true}>
              <Authenticate />
            </Route>
             It is used to redirect user to home/ default path whenever they enter not existed router
            <Route path="/*">
              <Redirect to="/" />
  </Route> */}
          {routes}
        </main>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
