/*To make header mobile friendly */
import React from "react";
import { CSSTransition } from "react-transition-group";
import { createPortal } from "react-dom";

import "./SideDrawer.css";

const SideDrawer = (props) => {
  const content = (
    // mounton enter and exit makes sure that it is added into dom and removed from dome based on its status
    <CSSTransition
      in={props.show}
      timeout={200}
      classNames="slide-in-left"
      mountOnEnter
      unmountOnExit
    >
      {/*here onclik is use to close drawer when user cick on any navigation */}
      <aside className="side-drawer" onClick={props.onClick}>
        {props.children}
      </aside>
    </CSSTransition>
  );
  return createPortal(content, document.getElementById("drawer-hook"));
};

export default SideDrawer;
