//these file is responsible for creating forms
import React, { useReducer, useEffect } from "react";

import { validate } from "../../Util/validators";
import "./Input.css";

//inputReducer is a function that acts as a reducer.
// A reducer is a function that takes the current state and an action,
//then returns the new state based on the action.

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        //to store old state ... used
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      };
    }
    default:
      return state;
  }
};
const Input = (props) => {
  // just like usestate use reducer also has two argumens
  const [inputState, dispatch] = useReducer(inputReducer, {
    //here props.value is when we update place and " " when we add place
    value: props.initialValue || "",
    isTouched: false,
    isValid: props.initialValid || false,
  });
  //this useEffect is to share update of value, validity to new place
  //means whenever there is change in value or its validity useeffect will share that to newplace.js
  //here I specifically took id, onInput, value, isvalid props to trigger
  //useEffect if I took props and inputState as general. it will make useEffect to go into infinite loop
  const { id, onInput } = props;
  const { value, isValid } = inputState;
  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, onInput, value, isValid]);
  const changeHandler = (event) => {
    // here dispatch function used to dispatch actions to the reducer
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };
  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
  };
  //this element is dymanic if user did not speciy input tag then it will tak text aria
  //value is wriiten using 2-way binding
  //onBlur is used to hide error message when user first time visit and did not started typing
  const element =
    props.element === "input" ? (
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onBlur={touchHandler}
        onChange={changeHandler}
        value={inputState.value}
      />
    ) : (
      <textarea
        id={props.id}
        rows={props.rows || 3}
        onBlur={touchHandler}
        onChange={changeHandler}
        value={inputState.value}
      />
    );
  console.log("input", inputState.value);
  return (
    // here div has dynamic clasname which will keep changing based on error
    <div
      className={`form-control ${
        !inputState.isValid && inputState.isTouched && "form-control--invalid"
      }`}
    >
      <label htmlFor={props.id}>{props.label}</label>
      {element}
      {/* to show error when input is invalid */}
      {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
    </div>
  );
};

export default Input;
