import React, { useEffect, useRef } from "react";

import "./Map.css";

const Map = (props) => {
  const mapRef = useRef();

  //   const { center, zoom } = props;
  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      zoom: props.zoom,
      center: props.center,
    });
    new window.google.maps.Marker({ position: props.center, map: map });
  }, [props.zoom, props.center]);

  return (
    <div
      ref={mapRef}
      className={`map ${props.className}`}
      style={props.style}
    ></div>
  );
};

export default Map;
