import React, { useState, useContext } from "react";

import { useForm } from "../../shared/components/hooks/form-hook";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/components/Util/validators";

import { AuthContext } from "../../shared/context/auth-context";
import Input from "../../shared/components/FormElements/Input/Input";
import Button from "../../shared/components/FormElements/Button/Button";
import "./Authentication.css";

const Authenticate = (props) => {
  const [isLoginMode, setIsLoginMode] = useState(true);
  const auth = useContext(AuthContext);
  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",

        isValid: false,
      },

      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const switchModeHandler = () => {
    //user is now in sigup page and switching to Login Mode
    //as n login we dont need name we are assigning undefined
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    }
    //user is in Login mode and switching to sigup mode
    //setform data will forward prefilled email and pass from login to signup form
    //it also add new field that is name validity
    else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };

  const placeSubmitHandler = (event) => {
    //to prevent reloading of page onsubmit event
    event.preventDefault();
    auth.login();
    //console.log(formState); //send this to backend
  };

  return (
    // <Card className="authentication">
    <React.Fragment>
      <form className="place-form" onSubmit={placeSubmitHandler}>
        <h2>{isLoginMode ? "Login Required" : "SignUP"}</h2>
        {!isLoginMode && (
          <Input
            id="name"
            element="input"
            label="Name"
            type="name"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a Name."
            onInput={inputHandler}
          />
        )}
        <Input
          id="email"
          element="input"
          label="E-Mail"
          type="email"
          validators={[VALIDATOR_EMAIL()]}
          errorText="Please enter a valid email."
          onInput={inputHandler}
        />
        <Input
          id="password"
          element="input"
          label="Password"
          type="password"
          validators={[VALIDATOR_MINLENGTH(8), VALIDATOR_MAXLENGTH(12)]}
          errorText="Please enter a valid Password of 8-12 characters."
          onInput={inputHandler}
        />
        <a href="/forgot">Forgot Password? </a>
        <hr />
        <Button type="submit" disabled={!formState.isValid}>
          {isLoginMode ? "LOGIN" : "SIGNUP"}
        </Button>
        <Button inverse onClick={switchModeHandler}>
          {isLoginMode ? "Switch to SIGN UP" : "Switch to LOGIN"}
        </Button>
      </form>
      {/* </Card> */}
    </React.Fragment>
  );
};

export default Authenticate;
