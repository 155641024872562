//here I have created custom hook as this is mostly common logic which is used in both newplace and update place file
//with just minor changes and to avoid code duplication I created custome hook

import { useReducer, useCallback } from "react";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;
      for (const inputId in state.inputs) {
        //this if for when vlue of input is undefined then it will move to next iteration
        if (!state.inputs[inputId]) {
          continue;
        }
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },

        isValid: formIsValid,
      };
    case "SET_DATA":
      return {
        inputs: action.inputs,
        isValid: action.formValidity,
      };

    default:
      return state;
  }
};

export const useForm = (initialInputs, initialFormValidity) => {
  //usereducer is use for keeping track of overall forms validity
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: initialInputs,

    //for validity of individual field
    //  { title: {
    //     value: "",

    //     isValid: false,
    //   },
    //   description: {
    //     value: "",
    //     isValid: false,
    //   },
    //   address: {
    //     value: "",
    //     isValid: false,
    //   },
    // },
    //is satus of overall form validity
    isValid: initialFormValidity,
  });
  //handler are used to check validity of diiferent field in form
  const inputHandler = useCallback((id, value, isValid) => {
    //as you can see in formreducer action is object that stores/refers values of id, value,isvalid which we are geeting from useeffect of input.js function
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);
  //This setFormData is use for fetching form data/value and checking its validity before putting it in update form
  const setFormData = useCallback((inputData, formValidity) => {
    dispatch({
      type: "SET_DATA",
      inputs: inputData,
      formIsValid: formValidity,
    });
  }, []);
  return [formState, inputHandler, setFormData];
};
